<template>
  <footer>
    <div class="footer-item">
      <p>
        &copy;<a href="https:/vnbknitting.com" class="href">VNBKnitting</a> All
        Rights Reserved.
      </p>
    </div>
    <div id="contact-us" class="footer-item">
      <a class="information" href="mailto:filipvnbknitting@gmail.com">Mail us</a>
      <a class="information" href="tel:016 342 6055">Call us</a>
      <a class="information" href="https://www.google.com/maps/place/43%C2%B009'10.6%22N+21%C2%B052'36.1%22E/@43.152931,21.87668,16z/data=!4m5!3m4!1s0x0:0x0!8m2!3d43.152931!4d21.8766804?hl=sr-RS" target=_blank>Find us</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
