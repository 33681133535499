<template>
  <nav class="nav-bar">
    <div class="nav-logo">
      <img src="/assets/images/logo.svg" />
    </div>
    <div class="nav-button" :class="{ 'nav-cross': menuToggled }" @click="toggleMenu()">
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="nav-items" :class="{ 'toggled': menuToggled }" id="topNav">
      <router-link to="/#home" @click.native="toggleMenu()">Home</router-link>
      <router-link to="/#products" @click.native="toggleMenu()">What We Produce</router-link>
      <router-link to="/#about-us" @click.native="toggleMenu()">Who we are </router-link>
      <router-link to="/our-basics" @click.native="toggleMenu()">Our basics</router-link>
      <router-link to="/#how-we-work" @click.native="toggleMenu()">How we work</router-link>
      <a href="#" @click.prevent="showVideoPopup">Show video</a>
      <router-link to="/#contact-us" @click.native="toggleMenu()">Contact Us</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppNav",
  data() {
    return {
      menuToggled: false
    }
  },
  methods: {
    showVideoPopup() {
      this.$emit("showvideo");
      this.toggleMenu();
    },
    toggleMenu() {
      this.menuToggled = !this.menuToggled;
    }
  }
};
</script>