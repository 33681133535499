import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import PageHome from "./components/PageHome.vue";
import PageOurBasics from "./components/PageOurBasics.vue";
import AppNav from "./components/AppNav.vue";
import AppFooter from "./components/AppFooter.vue";
import Slider from "./components/Slider.vue";
import ZoomOnHover from "vue-zoom-on-hover";

Vue.use(VueRouter)
Vue.use(ZoomOnHover);

const routes = [{
  path: "/",
  name: "home",
  component: PageHome
}, {
  path: "/our-basics",
  name: "our-basics",
  component: PageOurBasics,
}];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to) {
    if (to.name === "our-basics") {
      return {
        x: 0,
        y: 0
      }
    }
  }
});

Vue.config.productionTip = false

Vue.component("app-nav", AppNav);
Vue.component("app-footer", AppFooter);
Vue.component("slider", Slider);

new Vue({
  router: router,
  render: h => h(App),
}).$mount("#app");