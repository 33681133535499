<template>
  <div class="slide-container">
    <transition-group tag="div" class="slider" name="slide">
      <div
        v-for="number in [currentIndex]"
        :key="number"
        :style="
          'background-image: url(' +
          slides[Math.abs(currentIndex) % slides.length] +
          ')'
        "
      ></div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        "../assets/images/slider1.jpg",
        "../assets/images/slider2.jpg",
        "../assets/images/slider3.jpg",
      ],
      currentIndex: 0,
    };
  },
  mounted() {
    setInterval(() => {
      this.currentIndex = this.currentIndex + 1;
    }, 3000);
  },
};
</script>