<template>
  <div>
    <div id="home">
      <slider class="main"></slider>
    </div>
    <div class="page-wrapper">
      <div id="products">
        <div class="page-title">
          <h2>WHAT WE PRODUCE</h2>
        </div>
        <div class="home-products-wrapper">
          <div class="home-products-container">
            <div
              style="background-image: url('/assets/images/products-1.jpg')"
              class="home-products-image"
            ></div>
            <div class="home-products-description">
              <h3>JACQUARD</h3>
              <p>
                Jacquard is a knitting technique with which complex patterns can
                be knitted into the fabric. Such a woven pattern is much more
                colorfull than a pattern printed on it. Moreover, the back of
                the fabric is also very beautiful, the back of this fabric is
                exactly the negative image of the front.
              </p>
            </div>
          </div>
          <div class="home-products-container">
            <div
              style="background-image: url('/assets/images/products-2.jpg')"
              class="home-products-image"
            ></div>
            <div class="home-products-description">
              <h3>STRUCTURES</h3>
              <p>
                Knitting structures consists of different techniques and is
                possible in various structures. We knit the most diverse
                structures for many of our customers so that they are always
                assured of their own (time) design.
              </p>
            </div>
          </div>
          <div class="home-products-container">
            <div
              style="background-image: url('/assets/images/products-3.jpg')"
              class="home-products-image"
            ></div>
            <div class="home-products-description">
              <h3>AJOUR</h3>
              <p>
                Ajour is a knitting technique with which complex patterns can be
                knitted into the article. You can think of open structures and
                open motives.
              </p>
            </div>
          </div>
          <div class="home-products-container">
            <div
              style="background-image: url('/assets/images/products-4.jpg')"
              class="home-products-image"
            ></div>
            <div class="home-products-description">
              <h3>FANTASY</h3>
              <p>
                Every six months we provide a very varied and up-to-date range
                of yarns, from basic to very exclusive (often Italian) yarns.
                Part of our collection consists of the so-called fantasy yarns.
                These yarns are characterized by combinations of different types
                of materials, creating a special effect. We can also combine
                different yarns ourselves for a unique effect.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="about-us" class="home-about-wrapper">
        <div class="home-about-container">
          <div class="page-title">
            <h2>Who we are</h2>
          </div>
          <div class="home-about-container-text">
            <div class="home-about-text">
              <h3>50 YEARS OF EXPERIENCE</h3>
              <p>
                With over 50 years of experience in knitting technology, we are
                able to think along with you as a designer and propose creative
                solutions. We develop your idea into a wonderful product. The
                slogan of our factory is "We create fashion".
              </p>
            </div>
            <div class="home-about-text">
              <h3>GENERAL</h3>
              <p>
                Company "VNB Knitting" is a branch of "Dutch Knitting Company"
                which was founded on 1 May 2007 by Klaas Veijer and Robert Bode.
                Klaas and his family have been active in the knitting industry
                for many generations. Robert is an experienced entrepreneur who
                has been active for many years in the production of textile
                trade and in the purchase and sale of clothing.
              </p>
              <p>
                The factory in Lithuania was opened 10 years ago and since then
                there has been continuous investment in the latest techniques
                and in new knitting and making machines. Due to the enormous
                growth, we were expanded in 2014 to Serbia, where a second
                factory was set up. Since 2017, factory management has been left
                to Filip Z. which as general manager implemented the latests
                modern procedures of this industry. In the meantime "Dutch
                Knitting Company" has grown to become the market leader in
                knitted clothing and home wear.
              </p>
            </div>
            <div class="home-about-text">
              <h3>OUR VISION</h3>
              <p>
                Due to the constant quality that we deliver and our open and
                reliable communication, we have since built up a large and
                regular clientele. We treat the customer as we would like to be
                treated ourselves. Everything is negotiable and almost
                everything is possible. To guarantee quality, we continuously
                invest in new machines and in the latest technologies. The many
                years of experience of our programmers ensures that the most
                complex models come out perfectly from the machines.
              </p>
              <p>
                Before the products are packed, they are checked for, among
                other things, knitting quality, dimensions and labeling.
              </p>
            </div>
            <div class="home-about-text">
              <h3>OUR CUSTOMERS</h3>
              <p>
                The Dutch market is the most important market for the company.
                In addition, sales take place to customers in Germany, Denmark,
                Spain and Norway. Dutch Knitting Company focuses on a broad
                target group, including chain stores, wholesalers, retailers and
                specialists in corporate clothing. Thanks to the stable
                clientele of the company, we have been able to achieve
                considerable growth.
              </p>
              <p>
                The customer base includes Moscow, Circle of Trust, 10Days,
                Maratex GmbH, Anna van Toor, Zusss, The Stone, Costes, Kiddo
                Fashion, Knit-ted and Femmes du Sud.
              </p>
            </div>
          </div>
        </div>
        <div
          style="background-image: url('/assets/images/knitting-machine.jpg')"
          class="home-about-image"
        ></div>
      </div>
      <div id="how-we-work" class="home-how-we-work-wrapper">
        <div class="page-title">
          <h2>How we work</h2>
        </div>
        <div class="home-how-we-work-gallery">
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-1.jpg"
            ></zoom-on-hover>
          </div>
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-2.jpg"
            ></zoom-on-hover>
          </div>
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-3.jpg"
            ></zoom-on-hover>
          </div>
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-4.jpg"
            ></zoom-on-hover>
          </div>
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-5.jpg"
            ></zoom-on-hover>
          </div>
          <div class="home-how-we-work-gallery-image-container">
            <zoom-on-hover
              class="home-how-we-work-gallery-image"
              img-normal="/assets/images/gallery-6.jpg"
            ></zoom-on-hover>
          </div>
        </div>
        <div class="home-how-we-work-description">
          <div class="home-how-we-work-description-item">
            <h3>EVERYTHING IS POSSIBLE</h3>
            <p>
              That is our starting point in any case. Everything is negotiable
              and it almost always appears that it is possible. Our goal is to
              get the most out of each collection together with the customers!
            </p>
          </div>
          <div class="home-how-we-work-description-item">
            <h3>PRODUCTION</h3>
            <p>
              In our factory in Serbia we work with Stoll machines. We can offer
              variety of knitting gauges but we are focused the most in 12gg,
              10gg, 5gg and 3gg. But we can also offer production on 14gg, 8gg,
              7gg. In our factory we can also offer services of washing, drying,
              steaming and packing. All services are possible as factory has
              exclusive co-operation with all the companies from this industry.
            </p>
            <p>
              Types of knit such as coarse cables, intarsia jacquard, 12th
              division smoothing cloth, flattened or rib are all possible.
              Because we have the production in our own hands, we can quickly
              respond to trends and deliver quickly. We use a minimum purchase
              of 300 pieces per model, consisting of several sizes and / or
              colors.
            </p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "PageHome",
  methods: {
    scrollToHashId: function () {
      if (this.$route.name === "home" && this.$route.hash.indexOf("#") !== -1) {
        let element = document.querySelector(
          this.$route.hash.substring(this.$route.hash.indexOf("#"))
        );

        if (element) {
          window.scrollTo(0, element.offsetTop - 80);
        }
      }
    },
  },
  watch: {
    $route: function () {
      this.scrollToHashId();
    },
  },
  mounted() {
    this.scrollToHashId();
  },
};
</script>
