<template>
  <div id="app">
    <app-nav @showvideo="showVideo = true"></app-nav>
    <router-view></router-view>
    <app-footer></app-footer>
    <div class="video-container" v-if="showVideo">
      <video class="video-player" controls="controls">
        <source src="/assets/video/vnb-knitting.webm" type="video/webm" />
      </video>
      <div class="video-overlay" @click="showVideo = false"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showVideo: false
    }
  }
};
</script>