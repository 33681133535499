<template>
  <div class="our-basics-wrapper page-wrapper">
    <div class="our-basics-container">
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-1.jpg"
          img-zoom="/assets/images/our-basics-hq-1.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-2.jpg"
          img-zoom="/assets/images/our-basics-hq-2.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-3.jpg"
          img-zoom="/assets/images/our-basics-hq-3.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-4.jpg"
          img-zoom="/assets/images/our-basics-hq-4.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-5.jpg"
          img-zoom="/assets/images/our-basics-hq-5.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-6.jpg"
          img-zoom="/assets/images/our-basics-hq-6.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-7.jpg"
          img-zoom="/assets/images/our-basics-hq-7.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-8.jpg"
          img-zoom="/assets/images/our-basics-hq-8.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-9.jpg"
          img-zoom="/assets/images/our-basics-hq-9.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-10.jpg"
          img-zoom="/assets/images/our-basics-hq-10.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-11.jpg"
          img-zoom="/assets/images/our-basics-hq-11.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-12.jpg"
          img-zoom="/assets/images/our-basics-hq-12.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-13.jpg"
          img-zoom="/assets/images/our-basics-hq-13.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-14.jpg"
          img-zoom="/assets/images/our-basics-hq-14.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-15.jpg"
          img-zoom="/assets/images/our-basics-hq-15.jpg"
        ></zoom-on-hover>
      </div>
      <div class="our-basics-image-container">
        <zoom-on-hover
          img-normal="/assets/images/our-basics-scaled-16.jpg"
          img-zoom="/assets/images/our-basics-hq-16.jpg"
        ></zoom-on-hover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageOurBasics",
};
</script>